import React, { Component } from 'react';

class PagoRapidoKhipuError extends Component {

    render() {
        return(<>
            <p>Error KHIPU</p>
        </>)
    }

}

export default PagoRapidoKhipuError;