import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import Home from './Home/Home';
import DatosPersonales from './Perfil/DatosPersonales.jsx';
import Contratos from './Contratos/Contratos';
import DetailAgreement from './DetailAgreement/DetailAgreement';
import CartolaPayments from './CartolaPago/CartolaPayments';
import PreguntasFrecuentes from './PreguntasFrecuentes/PreguntasFrecuentes';
import HistorialPago from './HistorialPago/HistorialPago';
import PagoPie from './PagoPie/PagoPie';
import DetailPago from '../../components/DetailPago/DetailPago';
import PagoRapidoKhipuSuccess from '../../components/PagoRapidoKhipuSuccess';
import PagoRapidoKhipuError from '../../components/PagoRapidoKhipuError';
import AccesoFirmante from '../../pages/Acceso-Firmante';

const routesPrivate = (props) => {
  let Path = '/mi-sacramental/';
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={`${Path}resumen`} component={Home} />
        <Route
          exact
          path={`${Path}acceso-firmante`}
          component={AccesoFirmante}
        />
        <Route
          exact
          path={`${Path}datos-personales`}
          component={DatosPersonales}
        />
        <Route exact path={`${Path}contratos`} component={Contratos} />
        <Route exact path={`${Path}detalle/:id`} component={DetailAgreement} />
        <Route
          exact
          path={`${Path}cartola-de-pago`}
          component={CartolaPayments}
        />
        <Route
          exact
          path={`${Path}cartola-de-pago/:id`}
          component={CartolaPayments}
        />
        <Route
          exact
          path={`${Path}preguntas-frecuentes`}
          component={PreguntasFrecuentes}
        />
        <Route exact path={`${Path}acepta-y-pagar-pie`} component={PagoPie} />
        <Route exact path={`${Path}historial/:id`} component={HistorialPago} />
        <Route
          exact
          path={`${Path}/khipu/success`}
          component={PagoRapidoKhipuSuccess}
        />
        <Route
          exact
          path={`${Path}payment/khipu/Error`}
          component={PagoRapidoKhipuError}
        />
        <Route
          path={`${Path}detail/:origen/:buy_order/:token/:status/:code`}
          component={DetailPago}
        />
        <Redirect to={`${Path}resumen`} />
      </Switch>
    </BrowserRouter>
  );
};

export default routesPrivate;
